import React from 'react'
import Layout from '../components/Layout';
import { graphql, Link } from 'gatsby';
import { useMediaQuery } from '../hooks/useMediaQuery';
import { FaqPageTemplate } from './faq-page';
import SanitizeHTML from '../components/SanitizeHTML';
import ReactMarkdown from 'react-markdown'
import SignupButton from '../components/SignupButton';
import HowItWorks from '../components/HowItWorks';

export const IndexPageTemplate = (props) => {
  const isDesktop = useMediaQuery('(min-width: 900px)');
  const { frontpage } = props;

  const faq = {
    ...props.faq,
    questions: props.faq.questions.filter(f => f?.onFrontpage === true)
  };

  return <>
    <Layout isLandingPage={true}>

      <div className="primary-panel">
        <div className="layer">
          <div className="full">
            <div className="all-center hero-text">
              <div className="cent-text">
                <div style={{ textAlign: 'center', border: '0px solid red' }}>
                  {isDesktop && <div style={{ height: '100px' }}>&nbsp;</div>}
                  <h4 style={{ border: '0px solid blue' }}>
                    {frontpage.tagline}
                  </h4>
                </div>
                <div >
                  <div className="fp-card2">
                    <SanitizeHTML html={frontpage.subTagline} />
                    <div style={{ height: '40px' }}></div>
                    <Link to="/flytjendur-og-utgefendur">
                      <button className="btn btn-big btn-outlined more-info">Nánari upplýsingar</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <h1>
          Um SFH
        </h1>

        <ReactMarkdown>
          {frontpage.aboutSFH}
        </ReactMarkdown>

        <div>
          <h5>Hvernig virkar úthlutunarferlið?</h5>

          <HowItWorks />

        </div>
      </div>

      <div className="container alt-bg">
        <FaqPageTemplate questions={faq} />
      </div>

      <div className="container">
        <h1>Skráning í SFH</h1>
        <ReactMarkdown>
          {frontpage.signupText}
        </ReactMarkdown>

        <SignupButton />
      </div>

    </Layout>
  </>
}


const IndexPage = (props) => {
  const faq = props.data.faq.frontmatter;
  const frontpage = props.data.frontpage.frontmatter;

  return <IndexPageTemplate
    faq={faq}
    frontpage={frontpage}
  />
}

export default IndexPage;

export const indexQuery = graphql`
  query IndexQuery {
    frontpage: markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
      frontmatter {
        title
        tagline
        subTagline
        aboutSFH
        signupText
      }
    }
    faq: markdownRemark(frontmatter: {templateKey: {eq: "faq-page"}}) {
      frontmatter {
        title
        questions {
          question
          answer
          onFrontpage
        }
      }
    }
  }
  `;