
import React from 'react';

const CircleIcon = ({ iconName, children, red }) => {
  const circleIconClass = red ? 'circle-icon1' : 'circle-icon2';
  const circleIconTextClass = red ? 'circle-icon-text1' : 'circle-icon-text2';

  return <>
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <div className={'circle-icon ' + circleIconClass}>
        <div className="icns">
          <i className={'fas ' + iconName + ' ' + circleIconTextClass}></i>
        </div>
      </div>
      <div className="circle-icon-text">
        {children}
      </div>
    </div>
  </>
}

const HowItWorks = () => {
  return <>
    <CircleIcon iconName="fa-microphone" red={true}>
      Hljóðritun.
    </CircleIcon>

    <CircleIcon iconName="fa-edit">
      Skráð inn á vefsíðuna <a href="https://hljodrit.is">Hljóðrit.is</a> hverjir gefa út og taka þátt í tónlistarflutningi á hljóðritinu auk fleiri upplýsinga til að fá ISRC kóða fyrir hvert lag.
    </CircleIcon>

    <CircleIcon iconName="fa-volume-up">
      Hljóðritið er spilað á opinberum vettvangi svo sem á útvarpsstöðvum, verslunum, veitingahúsum og líkamsræktarstöðvum.
    </CircleIcon>

    <CircleIcon iconName="fa-coins">
      SFH innheimtir þóknun hjá útvarpsstöðvum og öðrum fyrirtækjum fyrir notkun hljóðritsins.
    </CircleIcon>

    <CircleIcon iconName="fa-file-alt">
      SFH fær spilunarskýrslur um hvaða tónlist er notuð og greiðir út til flytjenda og útgefenda eftir réttindum hver og eins.
    </CircleIcon>

    <CircleIcon iconName="fa-microphone" red={true}>
      Úthlutun.
    </CircleIcon>
  </>
}

export default HowItWorks;